<template>
	<div class="add_activity">
		<div class="form_left">
			<el-button class="btn2" type="primary" @click="link(1)"><!-- 由你创建 -->{{$t('activity.YNCJ')}}</el-button>
			<el-button class="btn2" type="primary" @click="link(2)"><!-- 感兴趣的 -->{{$t('activity.GXQ')}}</el-button>
			<el-button class="btn2" type="primary" @click="link(3)"><!-- 参与的 -->{{$t('add_activity.CYD')}}</el-button>
			<el-button class="btn2" type="primary" @click="link(4)"><!-- 往期活动 -->{{$t('add_activity.WQHD')}}</el-button>			
			<el-button class="btn1 mt20" type="primary"><!-- 发布活动 -->{{$t('add_activity.FBHD')}}</el-button>
		</div>
		
		<!-- 发布活动 -->
		<div class="form_center">
			<div class="center_top">
				<div class="top_dian">
					
				</div>
				<div class="top_text">
					<!-- 发布活动 -->{{$t('add_activity.FBHD')}}
				</div>
			</div>
			<!-- <img src="../../assets/img/form.png" class="login_logo" alt=""> -->
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
				<el-form-item :label="$t('activity.HDZT')" prop="theme_id">
					<div style="display: flex;align-items: center;">
						
						<el-select v-model="ruleForm.theme_id" :placeholder="$t('QXZ')">
							<el-option v-for="item in themeList" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
				</el-form-item>
				<el-form-item :label="$t('add_activity.HDMC')" prop="event_name">					
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.event_name" maxlength="1000" show-word-limit></el-input>
					</div>
				</el-form-item>
				<el-form-item :label="$t('HDSQ')" prop="event_time_zone">
					<div style="display: flex;align-items: center;">
						<!-- <el-input v-model="ruleForm.event_time_zone"></el-input> -->
						<el-select v-model="ruleForm.event_time_zone">
							<el-option  :label="item.data_value" :value="item.data_value" v-for="item in timeList" :key="item.data_code"></el-option>
						</el-select>
					</div>
				</el-form-item>
				<el-form-item :label="$t('HDKSSJ')" prop="event_start_time">
					<el-date-picker
					      v-model="ruleForm.event_start_time"
					      type="datetime"
						  :placeholder="$t('QXZ')"
						  value-format="yyyy-MM-dd HH:mm:ss"
					     >
					    </el-date-picker>
				</el-form-item>
				<el-form-item :label="$t('HDJSSJ')" prop="event_end_time">
					<el-date-picker
					      v-model="ruleForm.event_end_time"
					      type="datetime"
						  value-format="yyyy-MM-dd HH:mm:ss"
					      :placeholder="$t('QXZ')">
					    </el-date-picker>
				</el-form-item>
				<el-form-item :label="$t('add_activity.HDDZ')" prop="open_age_method">
					<el-radio-group v-model="ruleForm.event_type_method">
						<el-radio :label="item.data_code/1" v-for="item in eventType" :key="item.data_code">{{item.data_value}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<template v-if="ruleForm.event_type_method==0">
					<el-form-item :label="$t('XSFS')" prop="event_online_mehod">
						<el-radio-group v-model="ruleForm.event_online_mehod">
							<el-radio :label="item.data_code/1" v-for="item in eventOnline" :key="item.data_code">{{item.data_value}}</el-radio>
						</el-radio-group>
					</el-form-item>
					<!-- 如果线上Alis -->
					<template v-if="ruleForm.event_online_mehod==0">
					<el-form-item :label="$t('HYID')" prop="online_alis_id">
						<el-input v-model="ruleForm.online_alis_id" ></el-input>
					</el-form-item>
					<el-form-item :label="$t('HYPWD')" prop="online_alis_pwd">
						<el-input v-model="ruleForm.online_alis_pwd" ></el-input>
						<a href="https://www.alis.vip" target="_blank">
						<el-button type="primary"> <!-- 去Alis生成会议 -->{{$t('TOALIS')}} </el-button>
						</a>
					</el-form-item>
					</template>
					<!-- 如果线上外部 -->
					<!-- <el-form-item :label="$t('HDLJ')" prop="online_other_links" v-if="ruleForm.event_online_mehod==1">
						<el-input v-model="ruleForm.online_other_links" ></el-input>
					</el-form-item> -->
					<el-form-item :label="$t('HDLJ')" prop="online_other_links" >
						<el-input v-model="ruleForm.online_other_links" ></el-input>
					</el-form-item>
				</template>
				<el-form-item :label="$t('ADR')" prop="event_location" v-if="ruleForm.event_type_method==1">
					<el-input v-model="ruleForm.event_location"></el-input>
				</el-form-item>
				<el-form-item :label="$t('add_activity.HDRS')" prop="event_num_people">
					<el-input v-model="ruleForm.event_num_people" type="number" ></el-input>
				</el-form-item>
				
				
				
				
				<el-form-item :label="$t('add_activity.HDDD')" prop="event_confine_method">
					<el-radio-group v-model="ruleForm.event_confine_method">
						<el-radio :label="item.data_code/1" v-for="item in eventConfine" :key="item.data_code">{{item.data_value}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item :label="$t('add_activity.LBTP')" prop="listpics">
					<imglist :datas="ruleForm.listpics" :width="88"></imglist>
					<el-upload style="display: inline;"
					  class="avatar-uploader"
					   :action="$config.uploadUrl"
					  :show-file-list="false"
					  :on-success="upload_listpics"
					  :multiple="false"
					  >
					  
					  <i  class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<div>{{$t('LBIMGTIP')}}</div>
				</el-form-item>
				<el-form-item :label="$t('add_activity.ZSTP')" prop="showpics">
					<imglist :datas="ruleForm.showpics" :width="88"></imglist>
					<el-upload style="display: inline;"
					  class="avatar-uploader"
					  :action="$config.uploadUrl"
					  :show-file-list="false"
					  :on-success="upload_showpics"
					  :multiple="true"
					  >
					  
					  <i  class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					
					<div>{{$t('ZXIMGTIP')}}</div>
				</el-form-item>
				<el-form-item :label="$t('activity.HDJJ')" prop="event_desc">
					<el-input 
					  type="textarea"
					  :rows="4" maxlength="1000" show-word-limit
					  :placeholder="$t('QSR')"
					  v-model="ruleForm.event_desc">
					</el-input>
				</el-form-item>
				<el-form-item :label="$t('add_activity.HDXQ')" prop="event_details">
					<quill-editor
					      ref="myQuillEditor"
					      v-model="ruleForm.event_details"
					      :options="editorOption"
					  />
					 <el-upload
					 	style="display: none;"
					   class="avatar-uploader"
					   id="imguploader"
					   :action="$config.uploadUrl"
					   :show-file-list="false"
					   :on-success="upload_img"
					   list-type="picture-card"
					   >
					    <i class="el-icon-plus"></i>
					  
					 </el-upload>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')"><!-- 确认 -->{{$t('CONFIRM')}}</el-button>
					<el-button @click="goback"><!-- 取消 -->{{$t('CANCEL')}}</el-button>
				</el-form-item>
			</el-form>
			
			<!-- <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button> -->
		</div>
		
	</div>
</template>

<script>
	const toolbarOptions = [
	  ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
	  ['blockquote', 'code-block'], //引用，代码块
	  [{ 'header': 1 }, { 'header': 2 }], // 几级标题
	  [{ 'list': 'ordered' }, { 'list': 'bullet' }], // 有序列表，无序列表
	  [{ 'script': 'sub' }, { 'script': 'super' }], // 下角标，上角标
	  [{ 'indent': '-1' }, { 'indent': '+1' }], // 缩进
	  [{ 'direction': 'rtl' }], // 文字输入方向
	  [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
	  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],// 标题
	  [{ 'color': [] }, { 'background': [] }], // 颜色选择
	  [{ 'font': ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial'] }],// 字体
	  [{ 'align': [] }], // 居中
	  ['clean'], // 清除样式,
	  ['link', 'image'], // 上传图片、上传视频
	];
	import 'quill/dist/quill.core.css';
	import 'quill/dist/quill.snow.css';
	import 'quill/dist/quill.bubble.css';
	import { quillEditor } from 'vue-quill-editor';
	import imglist from '@/components/imglist.vue';
	export default {
		components: {
			imglist,
			quillEditor
		},
		data() {
			return {
				// 富文本
				

				editorOption: {
				       
				        theme: 'snow', //主题 snow/bubble
				        modules: {
				          history: {
				            delay: 1000,
				            maxStack: 50,
				            userOnly: false
				          },
				          toolbar: {
				            container: toolbarOptions,
				            handlers: {
				              image: function (value) {
				                if (value) {
				                  // 调用element的图片上传组件
				                  document.querySelector('#imguploader input').click();
				                } else {
				                  this.quill.format('image', false);
				                }
				              }
				            }
				          }
				        }
				      },
				eventType:[],
				eventOnline:[],
				eventConfine:[],
				themeList:[],
				timeList:[],
				ruleForm: {
					event_id: '',
					event_name: '',
					theme_id:'',
					event_time_zone: '',
					event_start_time: '',
					event_end_time: '',
					event_type_method: 0,
					event_location: '',
					event_online_mehod: 0,
					online_alis_id: '',
					online_alis_pwd: '',
					online_other_links: '',
					event_num_people: '',
					event_confine_method: 0,
					listpics: [],
					showpics: [],
					event_desc: '',
					event_details: ''
				},
				rules: {
					event_name: [{required: true,trigger: 'blur',message:this.$t('QSR')}],					
					event_time_zone: [{required: true,trigger: 'change',message:this.$t('QXZ')}],
					theme_id: [{required: true,trigger: 'blur',message:this.$t('QXZ')}],
					event_start_time: [{required: true,trigger: 'change',message:this.$t('QXZ')}],
					event_end_time: [{required: true,trigger: 'change',message:this.$t('QXZ')}],					
					event_location: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					online_alis_id: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					online_alis_pwd: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					online_other_links: [{required: true,trigger: 'blur',message:this.$t('QSR')}],					
					event_num_people: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					
					event_desc: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					event_details: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					listpics: [{type:'array',required: true,trigger: 'change',message:this.$t('QSC')}],
					showpics: [{type:'array',required: true,trigger: 'change',message:this.$t('QSC')}],
					
					
				}
			};
		},
		
		created() {
			
			this.getTheme();
			this.getUnit();
			//this.getTimes();
		},
		
		
		computed: {
		    editor() {
		      return this.$refs.myQuillEditor.quill;
		    }
		  },
		methods:{
			upload_img(res){
				console.log(res);
				// 获取富文本组件实例
				      let quill = this.$refs.myQuillEditor.quill;
				      // 如果上传成功
				      if (res) {
				        // 获取光标所在位置
				        let length = quill.getSelection().index;
				        // 插入图片，res为服务器返回的图片链接地址
				        quill.insertEmbed(length, 'image', res.data.Location);
				        // 调整光标到最后
				        quill.setSelection(length + 1);
				      } else {
				        // 提示信息，需引入Message
				        this.$message.error('图片插入失败！');
				      }
			},
			link(n){
				this.$router.push({name:'activity',params:{status:n}});
			},
			//上传列表图片
			upload_listpics(response, file, fileList){				
				
				let files=JSON.parse(JSON.stringify(this.ruleForm.listpics));
				files.push(response.data.Location);				
				this.ruleForm.listpics=[response.data.Location];
				//console.log(this.ruleForm.portfolio);
			},
			//上传展示图片
			upload_showpics(response, file, fileList){				
				
				let files=JSON.parse(JSON.stringify(this.ruleForm.showpics));
				files.push(response.data.Location);				
				this.ruleForm.showpics=files;
				//console.log(this.ruleForm.portfolio);
			},
			//获取字典数据
			async getUnit(){
				let res=await this.$request.get('/api/sysdict/getDictList');
				this.eventType=res.data.event_type_method.data;
				this.eventOnline=res.data.event_online_mehod.data;
				this.eventConfine=res.data.event_confine_method.data;
				this.timeList=res.data.event_time_zone.data;
			},
			//获取主题
			async getTheme(){
				let res=await this.$request.get('/api/event/getAllThemeList');
				this.themeList=res.data.records;
			},
			//获取时区
			async getTimes(){
				let res=await this.$request.get('/api/sysdict/getCountryCode');
				//this.timeList=res.data.records;
			},
			
			//提交
			async submitForm(){
				// console.log(this.ruleForm);
				let data=JSON.parse(JSON.stringify(this.ruleForm));
				
				console.log(data);
				this.$refs['ruleForm'].validate(async (valid) => {
				  if (valid) {					
					this.$request.post('/api/event/saveEvent',data).then((res)=>{
						this.$message({
							message:res.message,
							type:'success'
						});
						this.$router.push({path:'/activity'});
						
					}).catch(()=>{
						
					});					 
				  } else {
					this.$message({
						message:this.$t('QTXWZXX'),
						type:'warning'
					});
					
					// return false;
				  }
				});
			}
		}
	};
</script>

<style lang="less" >
	.ql-container.ql-snow{
	        line-height: normal !important;
	        height: 200px !important;
	        font-size:14px;
	}
	.add_activity{
		// padding: 30px;
		min-height: 100%;
		background-color: #212121;
		font-size: 14px;
		display: flex;
		justify-content: center;
		// flex-direction: column;
		// align-items: center;
		padding-top: 90px;
		padding-bottom: 90px;
		.form_left{
			display: flex;
			flex-direction: column;
			width: 286px;
			margin-right: 25px;
			.mt20{
				margin-top: 20px;
			}
			.btn1{
				background-color: #567BB6;
				border: none;
			}
			.btn2{
				background-color: #212121;
				border: 1px solid #567BB6;
				color: #fff;
			}
			.el-button{
				margin-left: 0;
				margin-bottom: 12px;
				width: 286px;
			}
		}
		.form_center {
			width: 874px;
			padding: 30px;
			background-color: #fff;
			border-radius: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			.center_top{
				height: 40px;
				display: flex;
				align-items: center;
				width: 771px;
				border-bottom: 1px solid #567BB6;
				margin-bottom: 20px;
				position: relative;
				.top_dian{
					width: 5px;
					height: 15px;
					background: #567BB6;
					margin-right: 10px;
				}
				.top_name{
					font-size: 17px;
					font-weight: bold;
				}
			}
				.avatar-uploader .el-upload {
				    border: 1px dashed #d9d9d9;
				    border-radius: 6px;
				    cursor: pointer;
				    position: relative;
				    overflow: hidden;
				  }
				  
				  .avatar-uploader .el-upload:hover {
				    border-color: #409EFF;
				  }
				  .avatar-uploader-icon {
				    font-size: 20px;
				    color: #8c939d;
				    width: 86px;
				    height: 86px;
				    line-height: 86px;
				    text-align: center;
				  }
				  .avatar {
				    width: 86px;
				    height: 86px;
				    display: block;
				  }
		}
		.top_btn{
			width: 120px;
			height: 29px;
			// background: #567BB6;
			border-radius: 7px;
			border: none;
			display: flex;
			align-items: center;
			justify-content: center;
			// margin-left: 30px;
			position: absolute;
			// top: 10px;
			right: 0;
		}
		.el-form{
			width: 580px;
			margin-bottom: 20px;
		}
		.picker_input{
			width: 260px;
		}
		.textarea{
			width: 630px;
		}
		.form_left{
			width: 286px;
		}
		.login_logo {
			width: 389px;
			height: 72px;
			margin-bottom: 30px;
		}

	}
</style>
